import React, { useState, useEffect } from "react";
import "../News Section All/NewsPage.css";
import { useTranslation } from "react-i18next";
import Collapse from '@mui/material/Collapse';
import Button from '@material-ui/core/Button';
import Footer from "../Footer/Footer";
import iitblaunch from "../../../Images/NewsPage/iitblaunch.png";
import asianetkannda from "../../../Images/News/asianet-kannada.jpeg";
import udaanlogo from "../../../Images/News/3.png";
import award from "../../../Images/News/award.png";
import aicte from "../../../Images/News/aicte-udaan-ack.png";
import demosubhassarkar from "../../../Images/News/demonstration.jpeg";
import vhs from "../../../Images/News/vishwahindisammelan.jpeg";
import vhs2 from "../../../Images/News/vhs2.jpeg";
import nbwf from "../../../Images/News/nbwf.jpeg";
import ackmalyalam from "../../../Images/News/ack-malyalam.jpeg"


function MediaCoverage() {
  const [mou, setMou] = useState(true);
  const [press, setPress] = useState(false);
  const [training, setTraining] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    const query = window.location.href;
    var url = new URL(query);
    var c = url.searchParams.get("type");
    if (c === "MoU") {
      // setMou(state => !mou)
    }
    if (c === "Press") {
      setPress(state => !press)
      setMou(state => !mou)
    }
    if (c === "Training") {
      setTraining(state => !training)
      setMou(state => !mou)
    }
  }, []);
  return (
    <>

      <div className="newspage">
        <p className="newspage_title">{t("media_coverage_title")}</p>
        <Button color="primary" style={{
          width: "100%", height: "100%", border: "2px solid #777",
          borderRadius: "10px", marginTop: "10px"
        }}
          onClick={() => setMou(state => !mou)}> <h2>MoU & Awards</h2></Button>
        <div style={{ display: "block" }}>
          <Collapse in={mou}>
          <div className="row">
              {/* Start Block 1 */}
              <div className="col-sm-4">
                  <div className="newspage_card media_coverage_card">
                    <img src={require('../../../Images/News/muzaffarpur6-10.jpeg')} alt="cstt" />
                    
                    <p className="newspage_description">
                    
                      "IIT Bombay's 'Project Udaan': Elevating the Learning Capacities of 6th to 10th Graders. As featured in the Muzaffarpur edition of Dainik Bhaskar, this two-year initiative has identified 25 schools in Muzaffarpur to implement Project Udaan and enhance student learning."  
                    </p>


                    <span>6-10वीं कक्षा के छात्रों की शिक्षा क्षमता को बढ़ाने वाला IIT बॉम्बे का प्रोजेक्ट 'उड़ान', 14 October 2023</span>
                  </div>
              </div>


              {/* <div className="row"> */}
              {/* Start Block 1 */}
              <div className="col-sm-4">
                  <div className="newspage_card media_coverage_card">
                    <img src={require('../../../Images/News/muzzafarpur12.jpeg')} alt="cstt" />
                    
                    <p className="newspage_description">
                    
                    The principals of high schools have received training for the implementation of 'Project Udaan' by IIT Bombay. This project aims to enhance the learning abilities of students in grades 6 to 10. As highlighted in the Muzaffarpur edition of Dainik Bhaskar, this two-year endeavor has selected 25 schools in Muzaffarpur to incorporate 'Project Udaan' and boost student learning.
                    </p>


                    <span>आईआईटी बॉम्बे के प्रोजेक्ट उड़ान के लिए जिले के हाई स्कूल और प्लस 2 के प्रधानाचार्य को दी गई प्रशिक्षण, 15 October 2023</span>
                  </div>
              </div>
              {/* Start Block 1 */}
              <div className="col-sm-4">
                <a
                  href="https://www.linkedin.com/posts/indian-institute-of-technology-bombay_after-a-mou-signing-ceremony-today-iit-bombay-activity-7016817378898440192-sOh9"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="newspage_card media_coverage_card">
                    <img src={require('../../../Images/News/profJhaUdaan.jpg')} alt="news6" style={{width:"80%"}} />

                    <p className="newspage_description">Prof. Girishnath Jha and Prof. Pushpak Bhattacharyya mentioned Project Udaan in  two-day summit of Technology & Bharatiya Bhasha on 30th September, 2023 at Dr. Ambedkar International Centre, Janpath, New Delhi.</p>
                    <span>Udaan project mentioned in Bharatiya Bhasa Utsav, 30 Sept 2023 </span>
                  </div>
                </a>
              </div>
            </div>

            <div className="row">
              {/* Start Block 1 */}
              <div className="col-sm-4">
                <a
                  href="https://www.iitb.ac.in/en/breaking-news/iit-bombay-and-cstt-sign-mou-project-udaan"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="newspage_card media_coverage_card">
                    <img src={require('../../../Images/News/CSTT.jpeg')} alt="cstt" style={{width:"80%"}}/>

                    <p className="newspage_description">UDAAN Project signs an MoU with CSTT to prepare and increase usage of scientific and technical terms into multiple Indian languages and domains.</p>


                    <span>MoU between UDAAN and CSTT, Government of India, 27 July 2023</span>
                  </div>
                </a>
              </div>


              {/* <div className="row"> */}
              {/* Start Block 1 */}
              <div className="col-sm-4">
                <a
                  href="https://www.linkedin.com/posts/indian-institute-of-technology-bombay_an-mou-was-signed-today-between-the-directorate-activity-7016815705408884736-gSlB"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="newspage_card media_coverage_card">
                    <img src={require('../../../Images/News/mou-dhe.jpeg')} alt="news6" />

                    <p className="newspage_description">An MoU was signed today between the Directorate of Higher Education and Director of Technical Education, Government of Maharashtra and the Indian Institute of Technology Bombay in the presence of Honorable Governor Shri. Bhagat Singh Koshyari, Minister of Education Shri. Chandrakant Patil and Principal Secretary - Directorate Of Higher Education Shri. Vikas Chandra Rastogi, IIT Bombay's Director Prof. Subhasis Chaudhuri and Dean (R&D) Prof. Milind Atrey.</p>


                    <span>MoU between UDAAN and Department of Higher Education, Govt of Maharashtra, 5 Jan 2023</span>
                  </div>
                </a>
              </div>
              {/* Start Block 1 */}
              <div className="col-sm-4">
                <a
                  href="https://www.linkedin.com/posts/indian-institute-of-technology-bombay_after-a-mou-signing-ceremony-today-iit-bombay-activity-7016817378898440192-sOh9"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="newspage_card media_coverage_card">
                    <img src={require('../../../Images/News/vc-presentation.jpeg')} alt="news6" />

                    <p className="newspage_description">Prof. Ganesh Ramakrishnan made a presentation to all the Vice Chancellors of Universities in Maharashtra, explaining how the UDAAN platform is an end-to-end Machine Translation framework that includes extensive use of lexical resources and a post-editing platform. The ecosystem enables rapid translation of documents for rapidly bringing technical works into publishable state. </p>
                    <span>Presentation to VCs of Universities of Maharashtra, 5 Jan 2023 </span>
                  </div>
                </a>
              </div>
            </div>
            {/* Start Block 1 */}

            <div className="row">
              <div className="col-sm-4">
                <a
                  href="https://www.iitb.ac.in/en/breaking-news/iit-bombay-professor-and-his-team-launch-%E2%80%98project-udaan%E2%80%99-ai-based-translation-software"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="newspage_card media_coverage_card">
                    <img src={iitblaunch} alt="news6" />

                    <p className="newspage_description">{t("media_coverage9")}</p>
                    <span>{t("media_coverage9_heading")}</span>
                  </div>
                </a>

              </div>
              {/* </div> */}
              {/* <div className="row">  */}
              {/* Start Block 1 */}
              <div className="col-sm-4">

                <a
                  href="https://www.aicte-india.org/sites/default/files/Acknowledgement%20to%20UDAAN%20TEAM%2C%20IIT%20Bombay%20for%20Technical%20Book%20Writing%20Scheme%20.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="newspage_card media_coverage_card">
                    <img src={aicte} alt="news6" />

                    <p className="newspage_description">AICTE acknowledges contribution of Udaan Project in translating diploma and engineering books under Technical Book Writing Scheme.</p>
                    <span>AICTE Press Release, 27 Dec 2022</span>
                  </div>
                </a>
                {/* Finish Block 1 */}
              </div>

              <div className="col-sm-4">

                {/* Start Block */}
                <a href="https://cods-comad.in/awards.php"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="newspage_card media_coverage_card">
                    <img src={award} alt="news6" />

                    <p className="newspage_description">UDAAN won Best Paper award in CODS-COMAD conference 2023</p>
                    <span>5 Jan 2023</span>
                  </div>
                </a>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-4">

                <a
                  href="https://www.youtube.com/watch?v=GZAo63hWbR8"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="newspage_card media_coverage_card">
                    <img src={require('../../../Images/News/hindidiwas.jpg')} alt="cstt" />

                    <p className="newspage_description">{t("media_coverage1")}</p>
                    <span>{t("media_coverage1_heading")}</span>
                  </div>
                </a>
              </div>
              {/* <div className="row"> */}
              {/* Start Block 1 */}
              <div className="col-sm-4">

                <a
                  href={ackmalyalam}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="newspage_card media_coverage_card">
                    <img src={ackmalyalam} alt="news1" />

                    <p className="newspage_description">UDAAN project was acknowledged in all the books translated into Malayalam under the project headed by AICTE.</p>
                    <span></span>
                  </div>
                </a>
                {/* </div> */}
                {/* </div> */}
              </div>
            </div>
          </Collapse>
        </div>
        <Button color="primary" style={{
          width: "100%", height: "100%", border: "2px solid #777",
          borderRadius: "10px", marginTop: "10px"
        }}
          onClick={() => setPress(state => !press)}> <h2>Press</h2></Button>
        <div style={{ display: "block" }}>
          <Collapse in={press}>
            <div className="row">
              {/* Start Block 1 */}
              <div className="col-sm-4">
                <a
                  href="https://www.youtube.com/watch?v=HmFhs8ksajo"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="newspage_card media_coverage_card">
                    <img src="https://i.ytimg.com/vi/1rqD-u061SU/hqdefault.jpg" alt="news2" />

                    <p className="newspage_description">Malayalam Book Release by Hon'ble President of India, 17 Mar 23</p>
                    <span>First batch of books translated into Malayalam with the help of UDAAN tool was released by Hon'ble President of India</span>
                  </div>
                </a>
              </div>

              <div className="col-sm-4">
                <a
                  href="https://twitter.com/dpradhanbjp/status/1554501943086592002"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="newspage_card media_coverage_card">
                    <img src={require('../../../Images/News/media3.jpeg')} alt="cstt" />

                    <p className="newspage_description">{t("media_coverage2")}</p>
                    <span>{t("media_coverage2_heading")}</span>
                  </div>
                </a>
              </div>

              <div className="col-sm-4">
                <a
                  href="https://twitter.com/Rajeev_GoI/status/1554712901562531840"
                  target="_blank"
                  rel="noreferrer"
                >


                  <div className="newspage_card media_coverage_card">
                    <img src={require('../../../Images/News/media4.jpeg')} alt="cstt" />

                    <p className="newspage_description">{t("media_coverage3")}</p>
                    <span>{t("media_coverage3_heading")}</span>
                  </div>
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4">
                <a
                  href="https://udaanproject.org/static/media/demonstration.44a05ac68c84d3d66daf.jpeg"
                  target="_blank"
                  rel="noreferrer"
                >


                  <div className="newspage_card media_coverage_card">
                    <img src={demosubhassarkar} alt="news3" />

                    <p className="newspage_description">Team giving a demonstration to Dr. Subhas Sarkar (Union Minister of State in the Ministry of Education), Padmashri  Dr. Chamu Krishna Shastry (Chairman, Bharatiya Bhasha Samiti) and Prof. M.P. Poonia (Vice-Chairman, AICTE).</p>
                    <span>Demonstration to Dignitaries</span>
                  </div>
                </a>
              </div>


              <div className="col-sm-4">
                <a
                  href="https://www.youtube.com/watch?v=oQ-HZMNil3o"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="newspage_card media_coverage_card">
                    <img src="https://i.ytimg.com/vi/oQ-HZMNil3o/hqdefault.jpg" alt="news2" />

                    <p className="newspage_description">Launch Video for Malayalam Book Release function, 17 Mar 23</p>
                    <span> Launch video for the release of Diploma & Engineering in Malayalam released by Honourable President of India Smt. Droupadi Murmu. Malayalam translation was completed with the help of UDAAN Project.</span>
                  </div>
                </a>
              </div>
              <div className="col-sm-4">
                <a href="https://www.youtube.com/watch?v=pL7Pd8zaa6Y&feature=youtu.be" target="_blank" rel="noreferrer">


                  <div className="newspage_card media_coverage_card">
                    <img src="https://i.ytimg.com/vi/pL7Pd8zaa6Y/hqdefault.jpg" alt="news3" />


                    <p className="newspage_description">സാങ്കേതിക പുസ്തകങ്ങൾ മാതൃഭാഷയിലേക്ക് വിവർത്തനം ചെയ്യുന്നു| Technical Books | Transation</p>
                    <span>17 March 2023</span>
                  </div>
                </a>
              </div>

              <div className="col-sm-4">
                <a href="https://newspaper.mathrubhumi.com/news/kerala/kerala-1.8397104" target="_blank" rel="noreferrer">


                  <div className="newspage_card media_coverage_card">
                    <img src="https://newspaper.mathrubhumi.com/image/contentid/policy:1.7011330:1645081450/image.jpg?$p=0f6e831&f=16x10&w=852&q=0.8" alt="news3" />

                    <p className="newspage_description">എൻജിനിയറിങ്‌ പുസ്തകങ്ങൾ മലയാളത്തിലായി: സാങ്കേതികപദങ്ങളുടെ മൊഴിമാറ്റത്തിനും സാങ്കേതികവിദ്യ</p>
                    <span>17 Mar 2023</span>
                  </div>
                </a>
              </div>


              <div className="col-sm-4">
                <a href={vhs} target="_blank" rel="noreferrer">


                  <div className="newspage_card media_coverage_card">
                    <img src={nbwf} alt="news3" />

                    <p className="newspage_description">UDAAN Project presence at National Book Fair, New Delhi</p>
                    <span>1-5 March 2023</span>
                  </div>
                </a>

              </div>
              <div className="col-sm-4">
                <a href={vhs} target="_blank" rel="noreferrer">


                  <div className="newspage_card media_coverage_card">
                    <img src={vhs} alt="news3" />

                    <p className="newspage_description">Prof Ganesh gave a talk on Project Udaan at Vishwa Hindi Sammelan organised by Govt of India in Fiji</p>
                    <span>17 Feb 2023</span>
                  </div>
                </a>
              </div>



              <div className="col-sm-4">
                <a href='https://ndtv.in/videos/project-udaan-will-teach-hindi-as-well-as-english-682716' target="_blank" rel="noreferrer">


                  <div className="newspage_card media_coverage_card">
                    <img src='https://c.ndtvimg.com/2023-02/gcqkss2s_profeccor_160x120_16_February_23.jpg' alt="news3" />

                    <p className="newspage_description">हिंदी के साथ-साथ अंग्रेजी भी सिखाएंगा प्रोजेक्ट 'उड़ान'</p>
                    <span>NDTV India(Video), 16 Feb 2023</span>
                  </div>
                </a>
              </div>

              <div className="col-sm-4">
                <a href='https://www.youtube.com/watch?v=98QgFFtNXtk' target="_blank" rel="noreferrer">


                  <div className="newspage_card media_coverage_card">
                    <img src='https://i.ytimg.com/vi/98QgFFtNXtk/hqdefault.jpg' alt="news3" />

                    <p className="newspage_description">Fiji में Project Udaan पर IIT Mumbai के Dr Ganesh Ramakrishnan से बातचीत </p>
                    <span>News 18 India(Video), 16 Feb 2023</span>
                  </div>
                </a>
              </div>

              <div className="col-sm-4">
                <a href='' target="_blank" rel="noreferrer">


                  <div className="newspage_card media_coverage_card">
                    <img src={vhs2} alt="news3" />

                    <p className="newspage_description">Prof Ganesh apprised about Project UDAAN to Shri V Muralidharan, Hon'ble Union Minister of State for External Affairs & Parliamentary Affairs </p>
                    <span>16 Feb 2023</span>
                  </div>
                </a>
              </div>
              <div className="col-sm-4">

                <a
                  href="https://news.careers360.com/aicte-recognises-iit-bombay-udaan-project-for-translation-of-technical-btech-diploma-books"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="newspage_card media_coverage_card">
                    <img src="https://cache.careers360.mobi/media/article_images/2022/12/28/aicte.jpeg" alt="news6" />

                    <p className="newspage_description">AICTE recognises IIT Bombay's UDAAN project for translation of technical books</p>
                    <span>Careers 360, 28 Dec 2022</span>
                  </div>
                </a>

              </div>
              <div className="col-sm-4">
                <a
                  href="https://twitter.com/ChamuKShastry/status/1558257925482614784"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="newspage_card media_coverage_card">
                    <img src={require('../../../Images/News/media5.jpeg')} alt="cstt" />

                    <p className="newspage_description">{t("media_coverage4")}</p>
                    <span>{t("media_coverage4_heading")}</span>
                  </div>
                </a>
              </div>


            </div>
            <div className="row">
              <div className="col-sm-4">
                <a
                  href="https://pib.gov.in/PressReleasePage.aspx?PRID=1754787"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="newspage_card media_coverage_card">
                    <img src="https://static.pib.gov.in/WriteReadData/userfiles/image/1G9DZ.jpg" alt="news5" />

                    <p className="newspage_description">{t("media_coverage5")}</p>
                    <span>{t("media_coverage5_heading")}</span>
                  </div>
                </a>
              </div>

              <div className="col-sm-4">
                <a
                  href="https://timesofindia.indiatimes.com/education/news/a-project-to-translate-engineering-books-into-hindi-vernacular-languages/articleshow/86199294.cms"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="newspage_card media_coverage_card">
                    <img src={udaanlogo} alt="news6" />

                    <p className="newspage_description">{t("media_coverage6")}</p>
                    <span>{t("media_coverage6_heading")}</span>
                  </div>
                </a>
              </div>
              <div className="col-sm-4">
                <a
                  href="https://timesofindia.indiatimes.com/india/artificial-intelligence-breaks-language-barrier-for-tech-edu/articleshow/86218960.cms"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="newspage_card media_coverage_card">
                    <img src={udaanlogo} alt="news6" />

                    <p className="newspage_description">{t("media_coverage7")}</p>
                    <span>{t("media_coverage7_heading")}</span>
                  </div>
                </a>
              </div>


            </div>

            <div className="row">

              <div className="col-sm-4">
                <a
                  href="https://twitter.com/iitbombay/status/1437710781890453516"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="newspage_card media_coverage_card">
                    <img src={require('../../../Images/News/media1.jpg')} alt="cstt" />

                    <p className="newspage_description">{t("media_coverage8")}</p>
                    <span>{t("media_coverage8_heading")}</span>
                  </div>
                </a>
              </div>

              <div className="col-sm-4">
                <a
                  href="https://www.youtube.com/watch?v=vroRNncHJw8"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="newspage_card media_coverage_card">
                    <img src={require('../../../Images/News/media2.jpg')} alt="cstt" />

                    <p className="newspage_description">{t("media_coverage10")}</p>
                    <span>{t("media_coverage10_heading")}</span>
                  </div>
                </a>
              </div>
              <div className="col-sm-4">


                <a
                  href="https://www.hindustantimes.com/cities/mumbai-news/iit-bombay-designs-ai-based-translation-app-to-help-students-from-linguistic-minorities-101631632296794.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="newspage_card media_coverage_card">
                    <img src={udaanlogo} alt="news3" style={{width:"80%"}}/>

                    <p className="newspage_description">IIT Bombay designs AI-based translation app to help students from linguistic minorities</p>
                    <span>Hindustan Times, 14 Sep 2021</span>
                  </div>
                </a>
              </div>
              <div className="col-sm-4">
                <a
                  href="https://analyticsindiamag.com/iit-bombay-launches-project-udaan-ai-based-translation-for-non-english-speakers/ "
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="newspage_card media_coverage_card">
                    <img src={udaanlogo} alt="news3" />

                    <p className="newspage_description">IIT Bombay Launches Project Udaan, AI-Based Translation For Non-English Speakers</p>
                    <span>Analytics India Mag, 16 Sep 2021</span>
                  </div>
                </a>
              </div>

              <div className="col-sm-4">
                <a
                  href="https://ianslife.in/culture/project-translate-engineering-books-hindi-vernacular-languages"
                  target="_blank"
                  rel="noreferrer"
                >


                  <div className="newspage_card media_coverage_card">
                    <img src={udaanlogo} alt="news3" />

                    <p className="newspage_description">A project to translate engineering books into Hindi, vernacular languages</p>
                    <span>IANS Life, 14 Sep 2021</span>
                  </div>
                </a>
              </div>
              <div className="col-sm-4">

                <a
                  href="https://www.deccanherald.com/national/west/iit-bombay-launches-ai-based-translation-project-udaan-1030309.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="newspage_card media_coverage_card">
                    <img src={udaanlogo} alt="news3" />

                    <p className="newspage_description">IIT Bombay launches AI-based translation ‘Project Udaan’</p>
                    <span>Deccan Herald, 14 Sep 2021</span>
                  </div>
                </a>
              </div>

              <div className="row">

              </div>
              <div className="col-sm-4">
                <a
                  href="https://www.orissapost.com/a-project-to-translate-engineering-books-into-hindi-vernacular-languages/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="newspage_card media_coverage_card">
                    <img src={udaanlogo} alt="news3" />

                    <p className="newspage_description">A project to translate engineering books into Hindi, vernacular languages.</p>
                    <span>Orissa Post, 14 Sep 2021</span>
                  </div>
                </a>


              </div>

              <div className="col-sm-4">
                <a
                  href="https://newsonair.com/2021/09/15/iit-bombay-launches-project-udaan-a-language-translator/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="newspage_card media_coverage_card">
                    <img src={udaanlogo} alt="news3" />

                    <p className="newspage_description">IIT Bombay launches Project Udaan, a language translator</p>
                    <span>PBNS, 15 Sep 2021</span>
                  </div>
                </a>

              </div>
              <div className="col-sm-4">

                <a
                  href="https://currentaffairs.adda247.com/iit-bombay-rolls-out-language-translator-project-udaan/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="newspage_card media_coverage_card">
                    <img src={udaanlogo} alt="news3" />

                    <p className="newspage_description">IIT Bombay rolls out language translator ‘Project Udaan’</p>
                    <span>Adda 247, 16 Sep 2021</span>
                  </div>
                </a>


              </div>

            </div>
            <div className="row">


              <div className="col-sm-4">

                <a
                  href="https://www.mid-day.com/mumbai/mumbai-news/article/engineering-texts-may-soon-be-in-hindi-other-languages-too-23192359"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="newspage_card media_coverage_card">
                    <img src={udaanlogo} alt="news3" />

                    <p className="newspage_description">Engineering texts may soon be in Hindi, other languages, too </p>
                    <span>Mid Day, 15 Sep 2021</span>

                  </div>
                </a>


              </div>
              <div className="col-sm-4">


                <a
                  href="https://www.punekarnews.in/project-udaan-an-ai-based-translation-software-ecosystem-iit-bombay-professor-and-his-team-launch-project-on-hindi-divas/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="newspage_card media_coverage_card">
                    <img src={udaanlogo} alt="news3" />

                    <p className="newspage_description">‘Project Udaan,’ an AI-based translation software ecosystem: IIT Bombay Professor and his team launch project on Hindi Divas</p>
                    <span>Punekar News, 14 Sep 2021</span>
                  </div>
                </a>

              </div>
              <div className="col-sm-4">
                <a
                  href="https://restofworld.org/2022/indian-universities-are-ditching-english-only-education/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="newspage_card media_coverage_card">
                    <img src={udaanlogo} alt="news3" />

                    <p className="newspage_description">Why Indian universities are ditching English-only education</p>
                    <span>Rest of World, 07 Feb 2022</span>
                  </div>
                </a>

              </div>
              <div className="col-sm-4">

                <a
                  href="https://www.mymahanagar.com/tech-gadgets/ai-based-project-udan-launched-by-iit-bombay-professor-on-hindi-divas/339734/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="newspage_card media_coverage_card">
                    <img src={udaanlogo} alt="news3" />

                    <p className="newspage_description">Project Udaan : AI तंत्रज्ञानाने इंजिनिअरींग पुस्तकांचे भाषांतर, IITB चा पुढाकार</p>
                    <span>My Mahanagar India Mag, 14 Sep 2021</span>
                  </div>
                </a>
              </div>
            </div>
          </Collapse>
        </div>
        <Button color="primary" style={{
          width: "100%", height: "100%", border: "2px solid #777",
          borderRadius: "10px", marginTop: "10px"
        }}
          onClick={() => setTraining(state => !training)}> <h2>Training</h2></Button>
        <div style={{ display: "block" }}>
          <Collapse in={training}>

            <div className="row">
              <div className="col-sm-4">
                <a
                  href="http://salamraigad.com/?p=10319"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="newspage_card media_coverage_card">
                    <img src="http://salamraigad.com/wp-content/uploads/2023/01/IMG-20230123-WA0012-1-768x576.jpg" alt="news6" />

                    <p className="newspage_description">डॉ.बाबासाहेब आंबेडकर तंत्रशास्त्र विद्यापीठामध्ये तांत्रिक पुस्तकांचे मराठी मध्ये भाषांतर करण्याकरिता दोन दिवसीय अभिमुखता मुक्ता कार्यक्रम संपन्न</p>
                    <span>23 Jan 2023</span>
                  </div>
                </a>
              </div>
              {/* <div className="row"> */}
              <div className="col-sm-4">
                {/* Finish Block 1 */}

                <a
                  href="
            https://kpepaper.asianetnews.com/c/71227462"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="newspage_card media_coverage_card">
                    <img src={asianetkannda} alt="news3" />

                    <p className="newspage_description">ವಿಶ್ವೇಶ್ವರಯ್ಯ ತಾಂತ್ರಿಕ ವಿವಿಯಿಂದ ಕನ್ನಡ ಪಠ್ಯಪುಸ್ತಕ</p>
                    <span>Asianet News, 22 Dec 2022</span>
                  </div>
                </a>
              </div>


              <div className="col-sm-4">
                <a
                  href="
            https://www.andhrajyothy.com/2022/andhra-pradesh/ananthapuram/teaching-engineering-in-mother-tongue-jntu-vc-974312.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="newspage_card media_coverage_card">
                    <img src={udaanlogo} alt="news3" />

                    <p className="newspage_description">మాతృభాషలో ఇంజనీరింగ్‌ బోధన: జేఎనటీయూ వీసీ</p>
                    <span>Andhra Jyothi, 22 Dec 2022</span>
                  </div>
                </a>
              </div>
              <div className="col-sm-4">
                {/* <!-- Start block one --> */}
                <a
                  href="https://www.thehindu.com/news/national/andhra-pradesh/jntua-holds-orientation-for-telugu-translators-of-engineering-books/article66289164.ece"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="newspage_card media_coverage_card">
                    <img src="https://th-i.thgim.com/public/incoming/8gocja/article66289900.ece/alternates/LANDSCAPE_1200/4842_21_12_2022_20_7_56_1_21JNTUABOOKSTRANSLATION.JPEG" alt="news3" />

                    <p className="newspage_description">{t("media_coverage11")}</p>
                    <span>{t("media_coverage11_heading")}</span>
                  </div>
                </a>
              </div>
              <div className="col-sm-4">
                {/* <!-- Finish block one --> */}
                {/* <!-- Start block one --> */}
                <a
                  href="https://www.eenadu.net/telugu-news/ap-top-news/general/2501/122236115"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="newspage_card media_coverage_card">
                    <img src={udaanlogo} alt="news3" />

                    <p className="newspage_description">సాంకేతిక పాఠ్యపుస్తకాలు తెలుగులోకి అనువాదం</p>
                    <span>Eenadu, 19 Dec 2022</span>
                  </div>
                </a>
              </div>

              <div className="col-sm-4">
                <a
                  href="https://punjabikhabarsaar.com/?p=27517"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="newspage_card media_coverage_card">
                    <img src="https://punjabikhabarsaar.com/wp-content/uploads/2022/12/3-min-1.jpeg" alt="news3" />

                    <p className="newspage_description">ਮਹਾਰਾਜਾ ਰਣਜੀਤ ਸਿੰਘ ਯੂਨੀਵਰਸਿਟੀ ਵਿਖੇ ਤਕਨੀਕੀ ਕਿਤਾਬਾਂ ਦੇ ਅਨੁਵਾਦ ’ਤੇ ਦੋ ਰੋਜ਼ਾ ਵਰਕਸ਼ਾਪ ਦਾ ਆਯੋਜਨ</p>
                    <span>Punjab Khabarsar, Dec 12 2022</span>
                  </div>
                </a>
              </div>
              <div className="col-sm-4">
                {/* <!-- Finish block one --> */}
                <a
                  href="https://yespunjab.com/mrs-ptu-organizes-two-day-workshop-on-technical-books-translation/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="newspage_card media_coverage_card">
                    <img src="https://yespunjab.com/wp-content/uploads/2022/12/MRSPTU-organizes-workshop-on-Technical-Books-Translation-696x497.jpg" alt="news3" />

                    <p className="newspage_description">{t("media_coverage12")}</p>
                    <span>{t("media_coverage12_heading")}</span>
                  </div>

                </a>
                {/* <!-- Finish block  --> */}
              </div>

            </div>
          </Collapse>
        </div>
      </div>
      <div className="news_section_right_box media_coverage_section_right_box">
        <div className="news_section_right_box_left">
          <p
            onClick={() => {
              window.open("https://docs.google.com/document/d/1A07SGO0HG1kE0zpunkVu4nOLyhH2xQsS0LDqHV3IZco/edit", "_blank");
            }}
            className="news_section_read_all"
          >
            {t("More Press Coverage")}
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default MediaCoverage;
