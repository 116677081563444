import "./Header.css";
import i18next from "i18next";
import Select from "react-select";
import { Link } from "react-scroll";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import "react-dropdown/style.css";
import logo from "../../../Images/logo.png";

const options = [
  { code: "en", value: "English", label: "English" },
  { code: "hi", value: "Hindi", label: "Hindi" },
];

function Header() {
  const [selected, setselected] = useState(null);

  const handlechange = (selected) => {
    setselected(selected);
    localStorage.setItem("language", selected.value);
    i18next.changeLanguage(selected.code);
  };
  const { t } = useTranslation();
  // const defaultoptions = options[0].value;
  const customStyles = {
    menuList: (provided, state) => ({
      color: "#011633",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };
  return (
    <div className="LandingHeader">
      <div className="LandingHeaderLeft">
        <img
          onClick={() => {
            window.location = "/";
          }}
          className="headerlogo"
          src={logo}
          alt="logo"
        />
        <div className="Junk2">
          <p className="headerlogotext"
            onClick={() => {
              window.location = "/";
            }}
          >
            {t("logo")}
          </p>
          <p className="Junk"> An initiative of IIT Bombay</p>
        </div>

        <ul>
        <li>
            <p
              onClick={() => {
                window.location = "./";
              }}
            >
              {t("Home")}
            </p>
          </li>
          <li>
            <p
              onClick={() => {
                window.location = "./AboutUs";
              }}
            >
              {t("About Us")}
            </p>
          </li>
          {/* <li>
            <Link to="./" spy={true} smooth={true} className="navItems">
              {t("Users")}
            </Link>
          </li> */}
          <li>
            <div className="dropdown">
              <span>Live Translate <i className="fa fa-angle-down" aria-hidden="true"></i></span>
              <div className="dropdown-content">
                <p><a target="_blank" className="nav-link" href="http://92.204.144.173/udaan-admin/translate">Sentence Translation(Administrative)</a></p>
                <p><a target="_blank" className="nav-link" href="http://92.204.144.173/udaan-admin/">File Translation(Admininstrative)</a></p>
                <p><a target="_blank" className="nav-link" href="https://translate.udaanproject.org/udaan/translate">Sentence Translation</a></p>
                <p><a target="_blank" className="nav-link" href="https://translate.udaanproject.org/udaan/">File Translation</a></p>
                <p><a target="_blank" className="nav-link" href=" https://translate.udaanproject.org/udaan-multilang/translate">Multilingual Sent Translation</a></p>
                <p><a target="_blank" className="nav-link" href=" https://translate.udaanproject.org/udaan-multilang/">Multilingual File Translation</a></p>
              </div>
            </div>
          </li>
          <li>
            <p
              onClick={() => {
                window.location = "./Publications";
              }}
            >
              {t("Publications")}
            </p>
          </li>
          <li>
            <p
              onClick={() => {
                window.location = "./Glossaries";
              }}
            >
              {t("Glossaries")}
            </p>
          </li>
          {/* <li>
            <Link to="/" spy={true} smooth={true} className="navItems">
              {t("contact")}
            </Link>
          </li> */}
          <li>
            <p
              onClick={() => {
                window.location = "./MediaCoverage";
              }}
            >
              {t("Media Coverage")}
            </p>
          </li>
          <li>
            <div className="dropdown">
              <span>Quick Links <i className="fa fa-angle-down" aria-hidden="true"></i></span>
              <div className="dropdown-content">
                <p><a className="nav-link" href="https://docs.google.com/document/d/1HGKTkCWLSzHkgWoWtQGvB0A8PAWyw86xVhVWeWTYxfA/edit?usp=sharing" target="_blank">User Guide</a></p>
                <p><a className="nav-link" href="/InstallationGuide">Installation Guide</a></p>
                <p><a className="nav-link" href="/DownloadTool">Download tool</a></p>
                <p
              onClick={() => {
                window.location = "./UDAANVideos";
              }}
            >
              {t("Video Tutorials")}
            </p>
                {/* <p><a className="nav-link" href="https://www.youtube.com/playlist?list=PLfL0bubCSMdzHx117x6c4mdi47ZjkTz7H" target="_blank">Video Tutorials</a></p> */}
                <p><a className="nav-link" href="https://github.com/UDAAN-LEAP/leap-pe-tool" target="_blank"> Source Code</a></p>
              </div>
            </div>
          </li>

          <li>
            <p
              onClick={() => {
                window.location = "/#Contact";
              }}
            >
              {t("Contact")}
            </p>
          </li>
        </ul>
      </div>

      <div className="LandingHeaderRight">
        <div className="LandingHeaderRightLeft">
          {/* <p
            onClick={() => {
              window.open("./SignUp", "_blank");
            }}
          >
            {t("signin/signup")}
          </p>
          <span>|</span> */}
          {/* <Select
            styles={customStyles}
            value={selected}
            options={options}
            placeholder={localStorage.getItem("language") || "English"}
            onChange={handlechange}
          /> */}
        </div>

        {/* <button
          onClick={() => {
            window.location = "./AboutUs#Donate";
          }}
        >
          {t("Donate")}
        </button> */}
      </div>
    </div>
  );
}

export default Header;
